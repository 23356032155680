input[type='text'],
input[type='email'],
select {
    font-size: 17px;
    line-height: 1.23543;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica,
        Arial, sans-serif;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    width: 100%;
    height: 3.3em;
    margin-bottom: 14px;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    color: #333;
    text-align: left;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-clip: padding-box;
}

textarea {
    width: 100%;
    height: 150px;
    font-size: 17px;
    line-height: 1.23543;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica,
        Arial, sans-serif;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;

    margin-bottom: 14px;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    color: #333;
    text-align: left;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-clip: padding-box;
}
input[type='text']:hover,
input[type='email']:hover,
textarea:hover {
    background-color: #eeeeee;
}

input[type='submit'] {
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica,
        Arial, sans-serif;
    background-color: #0070c9;
    background: linear-gradient(#42a1ec, #0070c9);
    border-color: #07c;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    padding: 13px 15px 14px;
}

input[type='submit']:hover {
    background-color: #147bcd;
    background: linear-gradient(#51a9ee, #147bcd);
    border-color: #1482d0;
    text-decoration: none;
}

.contact-form-container {
    margin-top: 2.5rem;
    padding-top: 40px;
}

.contact-form-container h1 {
    margin-bottom: 4rem;
}
